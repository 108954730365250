import {
  LogoCharacter,
  LogoContainer,
  LogoInner,
  LogoLeft,
  LogoName,
  LogoRight,
  LogoTop,
} from '@blistable/components/ui/logo/logo-container'
import { A } from '@solidjs/router'

export interface LogoProps {
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge'
  text?: boolean
  href: string
  pullLeft?: boolean
}

const config = [
  { id: 'tiny', size: 14 },
  { id: 'small', size: 20 },
  { id: 'medium', size: 30 },
  { id: 'large', size: 50 },
  { id: 'huge', size: 100 },
]

export function Logo(props: LogoProps) {
  const logo = config.find((item) => item.id === props.size) || config[0]
  return (
    <A href={props.href}>
      <div
        style={
          props.text && props.pullLeft
            ? { 'margin-left': `-${logo.size * 3}px` }
            : { 'margin-left': `${logo.size / 2}px` }
        }
      >
        <LogoContainer size={logo.size}>
          <LogoInner>
            <LogoLeft size={logo.size} />
            <LogoRight size={logo.size}>
              {props.text ? null : <LogoCharacter size={logo.size} />}
            </LogoRight>
            <LogoTop size={logo.size} />
          </LogoInner>
          {props.text ? <LogoName size={logo.size} /> : null}
        </LogoContainer>
      </div>
    </A>
  )
}
