/* @refresh reload */
import { render } from 'solid-js/web'
import { MetaProvider } from '@solidjs/meta'
import { Route, Router } from '@solidjs/router'
import App from '@blistable/App'
import '@blistable/index.css'

const root = document.getElementById('root')

render(
  () => (
    <MetaProvider>
      <Router>
        <Route path="/" component={App} />
      </Router>
    </MetaProvider>
  ),
  root!
)
