import { Logo } from '@blistable/components/ui/logo/logo'
import { Meta, Title } from '@solidjs/meta'

function App() {
  return (
    <>
      <Title>Blistable: Organize life's moments.</Title>
      <Meta
        name="description"
        content="Blistable helps you keep track of the big and small things in life effortlessly, making life a bit more organized and a lot more meaningful."
      />
      <div class="h-full w-full">
        <section class="relative bg-gray-800 overflow-hidden h-full">
          <img
            class="hidden lg:block absolute bottom-0 left-0 -mb-96 -ml-64"
            src="/images/zospace-assets/lines/circle.svg"
            alt="blistable loops!"
          ></img>
          <div class="relative h-full w-full flex justify-center items-center align-middle">
            <div class="flex flex-col gap-4 justify-center items-center align-middle">
              <div class="flex gap-4 justify-center items-center align-middle">
                <Logo size="tiny" href="/" />
                <div class="text-white font-heading text-2xl">blistable</div>
              </div>
              <div class="font-body text-white">Organize life's moments</div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default App
