import { ParentProps } from 'solid-js'

export function LogoContainer(props: ParentProps & { size: number }) {
  return (
    <div
      style={{
        padding: '0px',
        margin: '0px',
        position: 'relative',
        width: `${props.size}px`,
        height: `${props.size}px`,
        'box-sizing': 'border-box',
      }}
    >
      {props.children}
    </div>
  )
}

export function LogoCharacter({ size }: { size: number }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        color: 'white',
        'font-size': `${size / 2}px`,
        'font-weight': 900,
      }}
    >
      B
    </div>
  )
}

export function LogoInner(props: ParentProps) {
  return (
    <div
      style={{
        height: '0',
        width: '0',
        margin: 'auto',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
      }}
    >
      {props.children}
    </div>
  )
}

export function LogoLeft({ size }: { size: number }) {
  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        'transform-origin': '0 0',
        transform: 'rotate(90deg) skewX(-30deg) scaleY(0.864)',
      }}
      class="bg-pink-300"
    />
  )
}

export function LogoName({ size }: { size: number }) {
  return (
    <div
      style={{
        top: `-${size / 3.2}px`,
        position: 'absolute',
        left: `${size * 1.8}px`,
        'font-size': `${size / 0.9}px`,
        'font-weight': 900,
      }}
    >
      blistable
    </div>
  )
}

export function LogoRight(props: ParentProps & { size: number }) {
  return (
    <div
      style={{
        height: `${props.size}px`,
        width: `${props.size}px`,
        'transform-origin': '0 0',
        transform: 'rotate(-30deg) skewX(-30deg) scaleY(0.864)',
        position: 'relative',
        bottom: `${props.size}px`,
      }}
      class="bg-pink-500"
    >
      {props.children}
    </div>
  )
}

export function LogoTop({ size }: { size: number }) {
  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        'transform-origin': '0 0',
        transform: 'rotate(210deg) skew(-30deg) scaleY(0.864)',
        position: 'relative',
        bottom: `${size * 2}px`,
      }}
      class="bg-pink-400"
    />
  )
}
